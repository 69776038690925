<template lang="html">
<v-container>
  <v-card icon="mdi-account">
		<v-card-text>
		<h1 class="display-2 my-2">{{ ariaReadonly == true ? 'Ekspektasi Perilaku Dari Pimpinan Anda' : 'Pilih Dan Isi Ekspektasi Perilaku Rekan Kerja Anda'}}</h1>
		<hr>
    <v-btn
      icon
      dark
      @click="dialogFormEkspektasiPerilaku = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
		</v-card-text>
    <template v-if="ariaReadonly">
      <v-form ref="formLihatEkspektasiPerilaku" v-model="valid">
      <v-row class="mt-1">
        <v-col align="center" cols="12">
          <base-material-card
              class="v-card-profile"
              :avatar="dataEkspektasiPerilaku.foto"
              style="width:270px;margin-top:40px"
            >
            <v-card-text class="text-center" style="height:150px">
              <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
                {{dataEkspektasiPerilaku.nama}}
              </h4>
              <h4 class="font-weight-light grey--text mb-2">
                NIP. {{dataEkspektasiPerilaku.nip}}
              </h4>
              <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                {{dataEkspektasiPerilaku.jabatan}}
              </h4>
              <div class="mt-5">
              </div>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
      <br>
      <div v-if="dataEkspektasiPerilaku && triwulan == null">
      <v-row class="mt-1" >
        <!-- <v-col cols="1">
        </v-col> -->
        <v-col align="center" cols="12" class="ml-10" style="max-width:fit-content">
          <v-row>
            <v-col cols=2 align="left">
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan I</h3>
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan II</h3>
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan III</h3>
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan IV</h3>
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan IV-Akhir</h3>
            </v-col>
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_berorientasi_pelayanan" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>Ber</b>orientasi Pelayanan</h3>
              <label style="font-family:Segoe UI">- Memahami dan memenuhi kebutuhan masyarakat.</label>
              <br>
              <label style="font-family:Segoe UI">- Ramah, cekatan, solutif, dan dapat diandalkan.</label>
              <br>
              <label style="font-family:Segoe UI">- Melakukan perbaikan tiada henti.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_berorientasi_pelayanan }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_berorientasi_pelayanan }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_berorientasi_pelayanan }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_berorientasi_pelayanan }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_berorientasi_pelayanan }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_berorientasi_pelayanan }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_berorientasi_pelayanan }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_berorientasi_pelayanan }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_berorientasi_pelayanan }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_berorientasi_pelayanan }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_berorientasi_pelayanan" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row >
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>kuntabel</h3>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan jujur, bertanggung jawab, cermat, disiplin, dan berintegritas tinggi.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggunakan kekayaan dan barang milik negara secara bertanggung jawab, efektif, dan efisien.</label>
              <br>
              <label style="font-family:Segoe UI">- Tidak menyalahgunakan kewenangan jabatan.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_akuntabel }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_akuntabel }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_akuntabel }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_akuntabel }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_akuntabel }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_akuntabel }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_akuntabel }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_akuntabel }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_akuntabel }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_akuntabel }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_akuntabel" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Akuntabel" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_akuntabel" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>ompeten</h3>
              <label style="font-family:Segoe UI">- Meningkatkan kompetensi diri untuk menjawab tantangan yang selalu berubah.</label>
              <br>
              <label style="font-family:Segoe UI">- Membantu orang lain belajar.</label>
              <br>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan kualitas terbaik.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kompeten }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kompeten }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kompeten }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kompeten }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kompeten }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kompeten }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kompeten }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kompeten }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kompeten }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kompeten }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_kompeten" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Kompeten" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_kompeten" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>H</b>armonis</h3>
              <label style="font-family:Segoe UI">- Menghargai setiap orang apapun latar belakangnya.</label>
              <br>
              <label style="font-family:Segoe UI">- Suka menolong orang lain.</label>
              <br>
              <label style="font-family:Segoe UI">- Membangun lingkungan kerja yang kondusif.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_harmonis }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_harmonis }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_harmonis }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_harmonis }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_harmonis }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_harmonis }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_harmonis }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_harmonis }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_harmonis }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_harmonis }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_harmonis" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Harmonis" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_harmonis" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>L</b>oyal</h3>
              <label style="font-family:Segoe UI">- Memegang teguh ideologi Pancasila, Undang-Undang Dasar Negara Republik Indonesia 1945, NKRI serta pemerintahan yang sah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga nama baik sesama ASN, Pimpinan, Instansi, dan Negara.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga rahasia jabatan dan negara.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_loyal }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_loyal }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_loyal }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_loyal }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_loyal }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_loyal }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_loyal }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_loyal }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_loyal }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_loyal }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_loyal" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Loyal" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_loyal" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>daptif</h3>
              <label style="font-family:Segoe UI">- Cepat menyesuaikan diri menghadapi perubahan.</label>
              <br>
              <label style="font-family:Segoe UI">- Terus berinovasi dan mengembangkan kreativitas.</label>
              <br>
              <label style="font-family:Segoe UI">- Bertindak proaktif.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_adaptif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_adaptif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_adaptif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_adaptif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_adaptif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_adaptif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_adaptif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_adaptif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_adaptif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_adaptif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_adaptif" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Adaptif" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_adaptif" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <br>
          <br>
          <hr>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>olaboratif</h3>
              <label style="font-family:Segoe UI">- Memberi kesempatan kepada berbagai pihak untuk berkontribusi.</label>
              <br>
              <label style="font-family:Segoe UI">- Terbuka dalam bekerja sama untuk menghasilkan nilai tambah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggerakkan pemanfaatan berbagai sumber daya untuk tujuan bersama.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 1" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kolaboratif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kolaboratif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 2" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kolaboratif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kolaboratif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 3" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kolaboratif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kolaboratif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 4" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kolaboratif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kolaboratif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div v-if="data.triwulan == 'tahun'" class="text-left">
                  <div class="text-left">
                    <br>
                    <b><label>Ekspektasi:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.e_kolaboratif }}</p>
                  </div>
                  <div class="text-left">
                    <br>
                    <b><label>Umpan Balik:</label></b>
                    <p style="font-family:Segoe UI;"> {{ data.r_kolaboratif }}</p>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols=6>
              <v-textarea v-model="dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].e_kolaboratif" :disabled="ariaReadonly" label="Ekspektasi Rekan Anda Terkait Kolaboratif" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_kolaboratif" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      </div>
      <div v-else-if="dataEkspektasiPerilaku && triwulan != null">
      <v-row class="mt-1" >
        <!-- <v-col cols="1">
        </v-col> -->
        <v-col align="center" cols="12" class="ml-10" style="max-width:fit-content">
          <v-row>
            <v-col cols=2 align="left">
            </v-col>
            <v-col cols=2>
              <h3 style="font-family:Segoe UI;"> Triwulan {{triwulan}}</h3>
            </v-col>
            <v-col cols=3>
              <h3 style="font-family:Segoe UI;"> Umpan Balik Triwulan {{triwulan}}</h3>
            </v-col>
            <!-- <v-col cols=3>
              <v-textarea v-model="dataRespon.e_berorientasi_pelayanan" validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Respon Anda Terkait Ekspektasi Pimpinan" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>Ber</b>orientasi Pelayanan</h3>
              <label style="font-family:Segoe UI">- Memahami dan memenuhi kebutuhan masyarakat.</label>
              <br>
              <label style="font-family:Segoe UI">- Ramah, cekatan, solutif, dan dapat diandalkan.</label>
              <br>
              <label style="font-family:Segoe UI">- Melakukan perbaikan tiada henti.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_berorientasi_pelayanan }} Tes</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_berorientasi_pelayanan }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>kuntabel</h3>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan jujur, bertanggung jawab, cermat, disiplin, dan berintegritas tinggi.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggunakan kekayaan dan barang milik negara secara bertanggung jawab, efektif, dan efisien.</label>
              <br>
              <label style="font-family:Segoe UI">- Tidak menyalahgunakan kewenangan jabatan.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_akuntabel }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_akuntabel }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>ompeten</h3>
              <label style="font-family:Segoe UI">- Meningkatkan kompetensi diri untuk menjawab tantangan yang selalu berubah.</label>
              <br>
              <label style="font-family:Segoe UI">- Membantu orang lain belajar.</label>
              <br>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan kualitas terbaik.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_kompeten }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_kompeten }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>H</b>armonis</h3>
              <label style="font-family:Segoe UI">- Menghargai setiap orang apapun latar belakangnya.</label>
              <br>
              <label style="font-family:Segoe UI">- Suka menolong orang lain.</label>
              <br>
              <label style="font-family:Segoe UI">- Membangun lingkungan kerja yang kondusif.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_harmonis }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_harmonis }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>L</b>oyal</h3>
              <label style="font-family:Segoe UI">- Memegang teguh ideologi Pancasila, Undang-Undang Dasar Negara Republik Indonesia 1945, NKRI serta pemerintahan yang sah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga nama baik sesama ASN, Pimpinan, Instansi, dan Negara.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga rahasia jabatan dan negara.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_loyal }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_loyal }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>daptif</h3>
              <label style="font-family:Segoe UI">- Cepat menyesuaikan diri menghadapi perubahan.</label>
              <br>
              <label style="font-family:Segoe UI">- Terus berinovasi dan mengembangkan kreativitas.</label>
              <br>
              <label style="font-family:Segoe UI">- Bertindak proaktif.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_adaptif }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_adaptif }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=2 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>olaboratif</h3>
              <label style="font-family:Segoe UI">- Memberi kesempatan kepada berbagai pihak untuk berkontribusi.</label>
              <br>
              <label style="font-family:Segoe UI">- Terbuka dalam bekerja sama untuk menghasilkan nilai tambah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggerakkan pemanfaatan berbagai sumber daya untuk tujuan bersama.</label>
            </v-col>
            <v-col cols=2 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.e_kolaboratif }}</label>
                </div>
              </div>
            </v-col>
            <v-col cols=3 class="mt-2">
              <div v-for="(data, i) in dataEkspektasiPerilaku" :key="i">
                <div>
                  <label style="font-family:Segoe UI;" class="float-left"> {{ data.r_kolaboratif }}</label>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col cols="5">
            <v-alert
            color="blue-grey"
            dark
            dense
            icon="mdi-account-group"
            prominent
          >
            Tidak ada data ekspektasi perilaku kerja
          </v-alert>
          </v-col>
        </v-row>
      </div>
      </v-form>
    </template>
    <!-- <template v-else>
      <v-row v-if="dataPegawaiEkspektasiPerilaku.bawahan.length > 0" class="mt-5">
        <v-col v-for="(peg,i) in dataPegawaiEkspektasiPerilaku.bawahan" :key="i" align="center">
          <base-material-card
            class="v-card-profile"
            :avatar="peg.foto"
            style="width:270px;margin-top:40px"
          >
            <v-card-text class="text-center" style="height:150px">
              <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
                {{peg.peg_nama}}
              </h4>
              <h4 class="font-weight-light grey--text mb-2">
                NIP. {{peg.peg_nip}}
              </h4>
              <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                {{peg.jabatan_nama}}
              </h4>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
              <v-list min-width="200px">
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                    color="primary"
                    rounded
                    class="mr-0"
                    :loading="loadingEl == i"
                    @click="openDialogEkspektasiPerilaku(peg, i)"
                    :disabled="disabled_ekskpektasi"
                    :small="true"
                    >
                    <span> Isi Ekspektasi Perilaku </span>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-actions>

          </base-material-card>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col cols="5">
          <v-alert
          color="blue-grey"
          dark
          dense
          icon="mdi-account-group"
          prominent
        >
          Tidak ada pegawai
        </v-alert>
        </v-col>
      </v-row>
    </template> -->
    <v-card-text><hr></v-card-text>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
      <!-- <v-btn v-if="ariaReadonly == true" @click="simpanResponEkspektasiPerilakuKerja()" color="primary">Simpan Respon Euy</v-btn> -->
    </v-card-actions>
  </v-card>

	<!-- <v-dialog v-model="dialogFormEkspektasiPerilaku" persistent>
    <FormEkspektasiPerilakuBawahan ref="formDataEkspektasiBawahan" :dataPegawaiBawahan="dataPegawaiBawahan" :dataEkspektasiBawahan="dataEkspektasiBawahan" @onClose="(x)=>{dialogFormEkspektasiPerilaku=x}"/>
  </v-dialog> -->
</v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
// import FormEkspektasiPerilakuBawahan from './FormEkspektasiPerilakuBawahan.vue'
import {defaultRules} from '@/utils/lib'
import axios from 'axios'
import _g from '@/global'

  export default {
		components:{
			// FormEkspektasiPerilakuBawahan
		},
    props:{
			dataPegawaiEkspektasiPerilaku:Array||Object,
      ariaReadonly: Boolean,
      triwulan: String
		},
    data() {
      return {
        valid: true,
        rules:{
          ...defaultRules
        },
        // dataPegawaiBawahan: null,
        loadingEl:-1,
        // dialogFormEkspektasiPerilaku: false,
        // disabled_ekskpektasi: false,
        // dataEkspektasiBawahan:{},
        respon: false,
        dataRespon:{},
        autoGrow: false,
        noResize: true,
        dataEkspektasiPerilaku:[]
      };
    },
		watch:{
      triwulan:function(val){
        // this.dataEkspektasiPerilaku = []
        // let user = store.state.user
        // var current = store.state.user.data
        // SKPService.getEkspektasiPerilakuKerja(current.username, 'all', current.tahun).then(response=>{
        //   let res =response.data
        //   if(current.tahun >= 2022){
        //     var arr = []
        //     res.data.forEach(el => {
        //       if(val == el.triwulan){
        //         arr.push(el)
        //       }
        //     });
        //     res.data = arr
        //     this.dataEkspektasiPerilaku = res.data
        //     this.dataEkspektasiPerilaku.foto = user.data.data_asn.foto
        //     this.dataEkspektasiPerilaku.nip = user.data.data_asn.nip
        //     this.dataEkspektasiPerilaku.jabatan = user.data.data_asn.jabatan
        //     this.dataEkspektasiPerilaku.nama = user.data.data_asn.nama
        //   }
        // }).finally(()=>{
        // })
        var current = store.state.user.data
        var tahun = this.$store.getters["user/data"].tahun
        let user = store.state.user
        if(current.current_role.includes("PLT") || current.current_role.includes("Kepala UPT Puskesmas")){
          current.username = _g.overrideWithPLT(current.current_role)
        }

        var base_url_api = _g.getBaseURLAPIERK(tahun)
        const url_get_data = base_url_api + 'v1/get-ekspektasi-perilaku'
        var triwulan_ = this.triwulan
        this.dataEkspektasiPerilaku = []
        axios.post(url_get_data, {
          nip_atasan: null, nip: current.username, triwulan: triwulan_, tahun: tahun
        }).then(response => {
          if(tahun >= 2022){
            let res =response.data.data
            if(res != null){
              this.dataEkspektasiPerilaku = res
            }
            this.dataEkspektasiPerilaku.foto = user.data.data_asn.foto
            this.dataEkspektasiPerilaku.nip = user.data.data_asn.nip
            this.dataEkspektasiPerilaku.jabatan = user.data.data_asn.jabatan
            this.dataEkspektasiPerilaku.nama = user.data.data_asn.nama
          }
        }).finally(()=>{
        }).catch(err => {
          var error = err.message ? err.message : err.response.data.message
          this.loadingEl = -1
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      }
		},
    created(){
      this.load()
    },
    destroyed(){
      this.dataEkspektasiPerilaku = null
    },
    methods: {
      load(){
        this.dataEkspektasiPerilaku = []
        this.dataEkspektasiPerilaku = this.dataPegawaiEkspektasiPerilaku
      },
			closeEvent(){
				this.$emit('onClose',false)
			},
      // openDialogEkspektasiPerilaku(peg, idx){
      //   this.loadingEl = idx
      //   SKPService.getEkspektasiPerilakuKerja(peg.peg_nip).then(response=>{
      //     let res =response.data
      //     this.dataEkspektasiBawahan.e_berorientasi_pelayanan = ''
      //     this.dataEkspektasiBawahan.e_akuntabel = ''
      //     this.dataEkspektasiBawahan.e_kompeten = ''
      //     this.dataEkspektasiBawahan.e_harmonis = ''
      //     this.dataEkspektasiBawahan.e_loyal = ''
      //     this.dataEkspektasiBawahan.e_adaptif = ''
      //     this.dataEkspektasiBawahan.e_kolaboratif = ''
      //     if(res.data[0] != undefined){
      //       this.dataEkspektasiBawahan = res.data[0]
      //     }
      //   }).finally(()=>{
      //     this.dataPegawaiBawahan = peg
      //     this.dialogFormEkspektasiPerilaku = true
      //     this.loadingEl = -1
      //     this.$nextTick(() => {
      //       // block: 'end' will scroll to top of element instead of bottom
      //       this.$refs.formDataEkspektasiBawahan.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //     });
      //   })
      // },
      // simpanResponEkspektasiPerilakuKerja(){
      //   if (this.$refs.formLihatEkspektasiPerilaku.validate()){
      //     this.respon = true
      //     this.dataRespon.respon = this.respon
      //     this.loadingBtn = true
      //     SKPService.storeEkspektasiPerilakuKerja(this.dataRespon).then(response => {
      //       let res = response.data
      //       if (res.success){
      //         store.commit('snackbar/setSnack',{message: res.message, color:'success'})
      //       } else {
      //         store.commit('snackbar/setSnack',{message: res.message, color:'error'})
      //       }
      //     }).finally(()=>{
      //       this.loadingBtn=false
      //       this.closeEvent()
      //     })
      //   }
      // }
    },
  }
</script>
